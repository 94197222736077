.TrendingProducts {
	@apply py-3 md:p-6;
}

.TrendingProducts .left {
	@apply mb-4 md:mb-0;
	@apply flex-[0_0_25%] overflow-hidden h-[580px];
}

.TrendingProducts .right {
	@apply flex-1;
}

.TrendingProducts .products {
	/* @apply grid grid-cols-2 md:grid-cols-3 lg:grid-cols-5 gap-4; */
	@apply grid grid-cols-2 xl:grid-cols-4 gap-2;
}

.TrendingProducts .slider {
	@apply flex flex-col;
}

.TrendingProducts .slider .swiper {
}

@media (min-width: 1024px) and (max-width: 1280px) {
	.TrendingProducts .slider .swiper {
		@apply !max-h-[500vh];
	}
}

@media (max-width: 1023.5px) {
	.TrendingProducts .slider .swiper {
		@apply !max-h-[60vh];
	}
}

@media (max-width: 768px) {
	.TrendingProducts .slider .swiper {
		@apply !max-h-[50vh];
	}
}

.TrendingProducts .slider .products {
	@apply grid grid-cols-2 gap-2;
}

.TrendingProducts .header {
	@apply flex gap-4 items-center pb-4;
}
.TrendingProducts .right .header {
	@apply justify-between;
}
.TrendingProducts .header .title {
	@apply text-gray-300 text-[1.2rem] sm:text-[1.6rem] font-[500];
}
.TrendingProducts .header .buttons {
	@apply flex gap-2;
}
.TrendingProducts .header .buttons .button {
	@apply opacity-100 transition-opacity duration-300;
}
.TrendingProducts .header .buttons .button:disabled {
	@apply opacity-40;
}
.TrendingProducts .header .buttons .button:disabled .icon * {
	/* @apply stroke-gray1; */
}
.TrendingProducts .header .buttons .button .icon * {
	@apply stroke-green-500;
}

.TrendingProducts .right .banner {
	@apply mt-4;
}
