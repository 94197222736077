.sectionHeader {
	@apply py-4 text-black font-bold bg-white px-4 rounded-md;
}

.sectionHeader .contents {
	@apply flex items-center justify-between;
}
.sectionHeader.filter .contents {
	@apply block relative;
}

.sectionHeader .contents > .left {
	@apply flex gap-4 items-center;
}
.sectionHeader.filter .contents > .left {
	@apply flex flex-col items-center w-full;
}
.sectionHeader .contents > .left .title {
	@apply text-[1.2rem] sm:text-[1.6rem] font-[500];
}

.sectionHeader.filter .contents .right {
	@apply absolute right-0 top-7 2xl:top-auto 2xl:bottom-0 z-10;
}

.sectionHeader .contents > .right .arrow {
	@apply flex;
}
.sectionHeader .contents > .right .arrow .left {
	@apply mr-4;
}

.sectionHeader .contents .middle {
	@apply flex-1;
}
.sectionHeader .contents .middle .timeout {
	@apply md:ml-4;
}

/* Timeout */
.TimeoutItems {
	@apply flex gap-2;
}
.Timeout {
	@apply bg-gray-950 rounded-full w-8 h-8 relative;
	@apply sm:w-10 sm:h-10;
	@apply flex justify-center items-center;
}
.Timeout.last {
	@apply hidden md:flex;
}

.Timeout .text {
	@apply text-white text-[10px] sm:text-[14px];
}

/* Filter items */
.filterContainer {
	@apply w-[90%] max-w-[1140px] flex justify-center;
}
.filterItems {
	/* @apply inline-flex items-center relative px-4; */
	@apply relative px-10;
	@apply border-b border-[#AEAEAE]/60;
	@apply max-w-full overflow-x-auto overflow-y-visible;
}
.filterItems .filterItem {
	@apply flex gap-3 items-center relative py-6 px-1 h-10;
	@apply text-gray-600/80 font-[500] transition-colors;
	@apply text-[0.75rem] sm:text-sm md:text-base;
}
.filterItems .filterItem:hover,
.filterItems .filterItem.active {
	@apply text-orange-500;
}
.filterItems .filterItem::after {
	content: "";
	@apply absolute left-0 bottom-0 translate-y-1/2;
	@apply w-full h-[3px] bg-orange-500;
	@apply scale-x-0 transition-transform;
}
.filterItems .filterItem.active::after {
	@apply scale-x-100;
}
.filterItems .filterItem .logo {
}
.filterItems .filterItem .name {
	@apply leading-[0];
}

.changer .button .icon * {
	@apply stroke-orange-500;
}
.changer .button:disabled {
	@apply scale-0;
}
.changer .button {
	@apply absolute top-1/2 -translate-y-1/2 z-50 scale-100;
	@apply transition-transform;
}
.changer .button.left {
	/* @apply right-full; */
	@apply left-0;
}
.changer .button.right {
	/* @apply left-full; */
	@apply right-0;
}
