.PopularProducts {
	@apply pb-3;
}

.PopularProducts .contents {
	@apply flex gap-2 flex-col lg:flex-row;
}
.PopularProducts .contents.right {
	@apply lg:flex-row-reverse;
}
.PopularProducts .contents .left {
	@apply lg:flex-[0_0_23%];
	@apply w-full lg:h-auto hidden lg:block;
}
.PopularProducts .contents .right {
	/* @apply lg:flex-[0_0_75%]; */
	@apply flex-1;
}

.PopularProducts .contents .left {
	@apply pb-6 lg:pb-0;
}
.PopularProducts .contents .left .banner {
	@apply block h-full w-full relative;
}

.PopularProducts .contents .right .products {
	@apply grid grid-cols-2 md:grid-cols-4 gap-2;
}
