.CustomProductPopup {
	@apply fixed top-0 left-0 w-full h-full z-[9999] bg-black/20;
	@apply flex justify-center items-center;
}

.CustomProductPopup .contents {
	@apply relative w-full max-w-[80vw] bg-white rounded-lg shadow-lg;
	@apply flex flex-col;
}

.CustomProductPopup .contents .top {
	@apply flex justify-between p-4 relative;
	@apply border-b border-gray-300;
}
.CustomProductPopup .contents .top .closeButton {
	@apply flex items-center justify-center w-6 h-6 rounded-full cursor-pointer;
	@apply bg-black/10;
	@apply absolute -top-3 -right-3;
}

.CustomProductPopup .contents .top .left {
	@apply flex-1 mr-4;
	@apply relative;
}

.CustomProductPopup .contents .body {
}
.CustomProductPopup .contents .body .products {
	@apply h-[70vh] p-4 overflow-y-auto grid grid-cols-3 gap-2;
	grid-template-rows: repeat(auto-fill, 95px);
}
.CustomProductPopup .contents .body .products .productImage {
	@apply flex-[0_0_20%] pr-2 h-full overflow-hidden;
}
.CustomProductPopup .contents .body .products .productImage img {
	@apply w-full h-full object-contain object-center;
}

.BulkSkuPopup {
	@apply fixed top-0 left-0 w-full h-full z-[9999] bg-black/20;
	@apply flex justify-center items-center;
}
.BulkSkuPopup .contents {
	@apply relative w-full max-w-[70vw] bg-white rounded-lg shadow-lg;
	@apply flex flex-col;
}

.BulkSkuPopup .BulkSkuPopup__header {
	@apply flex justify-between p-4 relative;
	@apply border-b border-gray-300;
}
.BulkSkuPopup .BulkSkuPopup__header .BulkSkuPopup__closeBtn {
	@apply flex items-center justify-center w-6 h-6 rounded-full cursor-pointer;
	@apply bg-black/10;
	@apply absolute -top-3 -right-3;
}
.BulkSkuPopup .BulkSkuPopup__body {
	@apply flex flex-col;
}
.BulkSkuPopup .BulkSkuPopup__body textarea {
	@apply w-full h-[60vh] p-4;
	@apply resize-none;
	outline: none;
}
.BulkSkuPopup .BulkSkuPopup__footer {
	@apply flex justify-end p-4;
}
.BulkSkuPopup .BulkSkuPopup__footer h3 {
	@apply text-xl font-semibold;
}
.BulkSkuPopup .BulkSkuPopup__footer ul {
	@apply flex gap-4;
}
