.CategorySearchV2 {
	@apply fixed w-full h-full left-0 top-0 z-[999];
	@apply flex items-center justify-center;
	@apply bg-black/10;
}

.CategorySearchV2 .contents {
	@apply relative p-6 bg-white rounded-lg shadow-lg;
	@apply max-w-[80vw];
	@apply -translate-y-4;
}
.CategorySearchV2 .contents .close {
	@apply absolute -right-5 -top-5;
	@apply w-6 h-6 flex items-center justify-center;
	@apply bg-white/50 rounded-full cursor-pointer;
}
.CategorySearchV2 .contents .close > * {
	@apply stroke-1 w-4 text-black/90;
}
.CategorySearchV2 .contents {
}
.CategorySearchV2 .contents .items {
	@apply flex gap-4;
}
.CategorySearchV2 .contents .items .list {
}

/** CategoryList */
.CategoryList {
	@apply relative h-[70vh] border-l first:border-none border-gray-300 text-black;
	@apply overflow-y-auto;
}
.CategoryList .backButton {
	@apply ml-6 py-2 flex gap-2 items-center text-[0.9rem] font-semibold text-orange-500;
}
.CategoryList .backButton .icon * {
	/* @apply stroke-primary-orange; */
}
.CategoryList .item {
	@apply p-4 py-2;
	@apply flex items-center justify-between min-w-[200px];
}
.CategoryList .item {
	@apply text-black cursor-pointer;
}
.CategoryList .item.active .title {
	/* @apply bg-bgs-lightGray/10; */
	@apply text-orange-500;
}

.CategoryList .item:hover .title {
	/* @apply bg-bgs-lightGray/10; */
	@apply text-orange-500;
}
.CategoryList .item .title {
	@apply px-4 text-[0.9rem];
}
.CategoryList .item .icon {
	@apply px-2 rounded-xl cursor-pointer;
}

.CategoryList .item.active .icon *,
.CategoryList .item:hover .icon * {
	@apply stroke-orange-500;
}

.CategoryList .search {
	@apply px-2 pb-1 relative bg-white;
	@apply sticky top-0 left-0;
}
.CategoryList .search input {
}
.CategoryList .search .closeButton {
	@apply absolute right-4 top-1/2 -translate-y-1/2;
	@apply w-6 h-6 flex items-center justify-center;
	@apply bg-white/50 rounded-full cursor-pointer;
}
.CategoryList .search .closeButton > * {
	@apply stroke-1 w-4 text-black/90;
}

/* Breadcumb */
.CategorySearchV2 .contents .items .breadcumb {
	@apply absolute left-0 top-full translate-y-2;
	@apply flex gap-2 items-center;
	@apply p-4 py-2 bg-white rounded-lg shadow-lg;
}
.CategorySearchV2 .contents .items .breadcumb .breadcumbItem {
	@apply flex gap-2 items-center;
}
