.indemand__container {
    @apply bg-white drop-shadow-md rounded-md cursor-pointer p-3; 
}
.indemand__wrapper {
    @apply flex items-center justify-center;
}
.indemand__wrapper img {
    @apply w-full h-32 aspect-auto object-contain object-center;
}
.indemand__wrapper h1 {
    @apply text-xs font-light tracking-wide uppercase text-slate-500;
}