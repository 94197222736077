@tailwind base;
@tailwind components;
@tailwind utilities;

.container {
	@apply max-w-screen-xl w-[90vw] mx-auto;
}

.ck-content {
	min-height: 500px;
	max-height: auto;
}

.tox-statusbar__branding {
	@apply !hidden;
}

.object-cover {
	filter: none !important;
}

.chock-icon * {
	@apply stroke-black/60 transition-colors;
}
.chock-icon:hover * {
	@apply stroke-black;
}

.page-loader {
	@apply fixed top-0 left-0 w-full h-full py-4 bg-white/70;
	@apply z-[999] flex justify-center items-center;
	backdrop-filter: saturate(180%) blur(5px);
}

.page-loader .bg {
	@apply w-full opacity-10;
}

input[type="color"] {
	@apply h-14 relative;

	&::after {
		content: "SELECT COLOR";
		@apply absolute left-0 top-0 z-10 w-full h-full flex justify-center items-center text-gray-500 drop-shadow-[2px_2px_2px_rgba(255,255,255,0.5)] text-[0.8rem];
	}
}

#gjs {
	padding-bottom: 50px; /* Adjust as needed */
}

@keyframes slideLeft {
	0% {
		transform: translateX(110%);
	}
	100% {
		transform: translateX(0);
	}
}
