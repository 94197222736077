.ProductWithThreeBanner {
	@apply pb-3 md:pb-6;
}

.ProductWithThreeBanner .products {
	/* @apply grid grid-cols-2 md:grid-cols-3 lg:grid-cols-5 gap-4; */
	@apply flex-1 grid grid-cols-2 lg:grid-cols-4 gap-2;
	/* @apply flex gap-2; */
}

.ProductWithThreeBanner .slider {
	/* @apply hidden; */
}

.ProductWithThreeBanner .slider .swiper {
	@apply relative h-72;
}

.ProductWithThreeBanner .slider .products {
	@apply grid grid-cols-2 gap-2;
}

.ProductWithThreeBanner .right .banner {
	@apply mt-4;
}

.ProductWithThreeBanner .body .banners {
	/* @apply mt-2 xl:mt-0 flex-[0_0_28%] max-h-[468px] grid grid-cols-2 xl:grid-cols-1 xl:grid-rows-2 gap-2; */
	/* xl:flex-[0_0_25%] */
	/* @apply w-full h-full; */
	@apply xl:flex-[0_0_27.5%] 2xl:flex-[0_0_27%] grid grid-cols-2 mt-2 xl:mt-0 xl:flex flex-col gap-2;
}
