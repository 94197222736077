.ShowBannersSection {
	@apply pb-2;
}

.ShowBannersSection .contents {
	@apply grid grid-cols-2 gap-2 md:gap-4;

	@screen lg {
		grid-template-columns: repeat(var(--col), minmax(0, 1fr)) !important;
	}
	@screen md {
		grid-template-columns: repeat(var(--col-md), minmax(0, 1fr));
	}
}
.ShowBannersSection.col2 .contents {
	@apply grid-cols-2;
}
.ShowBannersSection.col1 .contents {
	@apply grid-cols-1;
}

.ShowBannersSection .contents .banner {
	@apply relative;
}

.ShowBannersSection .contents .banner.wide {
	@apply col-span-2 md:col-span-3;
}
