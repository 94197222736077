/** Default type = lg **/
.ProductCard {
	@apply w-full h-full p-0 bg-white cursor-pointer relative;
	/* @apply w-[312px]; */
	@apply overflow-hidden;
	@apply border-[0.5px] border-[#AEAEAE]/20; /* border-primary-tints1; */
	@apply transition-[shadow_colors] duration-[400ms];
}
.ProductCard.lg {
	@apply border-none rounded-[.25rem];
}
.ProductCard:is(.lg, .sm):hover {
	@apply border-transparent;
	box-shadow: 0 12px 12px -6px rgba(0, 0, 0, 0.06);
}
.ProductCard.xs * {
	box-sizing: content-box;
}
.ProductCard.lg2 {
	@apply p-0;
}

.ProductCard.md,
.ProductCard.xs {
	@apply flex items-center;
}
.ProductCard.full {
	@apply md:flex items-center;
}

.ProductCard .cardTop {
	@apply w-full bg-white;
	@apply relative overflow-hidden;
}
.ProductCard.xs .cardTop {
	@apply w-32 2xl:w-40 flex-[0_0_35%];
}
.ProductCard.cart .cardTop {
	@apply w-28 2xl:w-36;
}
.ProductCard.full .cardTop {
	@apply flex-[0_0_60%] h-[200px] md:h-[400px] relative md:mr-4 bg-transparent;
}
.ProductCard.md .cardTop {
	@apply flex-[0_0_55%] h-full grid items-center;
}
.ProductCard.lg2 .cardTop {
	@apply md:transition-transform duration-[400ms];
}
.ProductCard.lg2 .cardBody,
.ProductCard.sm .cardBody {
	@apply bg-white;
}
.ProductCard.lg2 .cardTop .cardImage,
.ProductCard.sm .cardTop .cardImage {
	@apply transition-transform duration-500;
	@apply translate-y-0;
}

.ProductCard .cardTop .cardImage {
	@apply w-full;
}
.ProductCard.full .cardTop .cardImage {
	@apply h-full;
}
.ProductCard.xs .cardTop .cardImage {
	@apply !h-full;
}

.ProductCard .cardTop .overlay {
	@apply absolute top-0 left-0 transition-opacity duration-700;
	@apply w-full h-full bg-gray-400/50 opacity-0;
}

.ProductCard:hover .cardTop .overlay {
	@apply md:opacity-100;
}

.ProductCard .cardTop .freeDeliveryTag {
	@apply absolute left-3 bottom-1 w-[20%] min-w-[30px] h-14;
}
.ProductCard .cardTop .freeDeliveryTag .image {
	@apply drop-shadow-lg;
}

.ProductCard .cardTop .discountTag {
	@apply absolute top-3 left-3;
}
.ProductCard.xs .cardTop .discountTag {
	@apply py-[2px] px-1.5 2xl:py-[6px] 2xl:px-[10px];
	@apply top-2 left-2;
}
.ProductCard.sm .cardTop .discountTag {
	@apply py-[3px] px-2;
}

.ProductCard .cardTop .buttons {
	@apply absolute top-0 right-0 h-full pr-4 transition-transform duration-500;
	@apply hidden md:flex flex-col justify-center items-center;
}
.ProductCard .cardTop .buttons .button {
	@apply relative w-8 h-8 bg-white rounded-full;
	@apply flex justify-center items-center my-1;
	@apply transition-colors duration-700;
	/* @apply -translate-x-8; */
	animation: popOut 500ms ease forwards;
}
.ProductCard.sm .cardTop .buttons .button,
.ProductCard.md .cardTop .buttons .button {
	@apply relative w-7 h-7;
}
.ProductCard:hover .cardTop .buttons .button {
	animation: popIn 500ms ease forwards;
}
.ProductCard .cardTop .buttons .button:hover {
	@apply bg-orange-500;
}
.ProductCard .cardTop .buttons .button .icon {
	/* @apply absolute top-0 left-0;
    @apply w-full h-full; */
}
.ProductCard .cardTop .buttons .button .icon * {
	@apply stroke-gray-600 transition-all;
	fill: none;
}
.ProductCard .cardTop .buttons .button:hover .icon * {
	@apply stroke-white;
}
.ProductCard .cardTop .buttons .button .icon.active * {
	@apply !stroke-transparent transition-all fill-orange-500;
}
.ProductCard .cardTop .buttons .button.loading {
	@apply relative;
}
.ProductCard .cardTop .buttons .button.loading::after {
	content: "";
	@apply absolute w-full h-full left-0 top-0 z-10;
	@apply border-l border-x-pink-200 rounded-full animate-spin;
}

.ProductCard .cardBody {
	@apply p-2 sm:py-4 sm:p-4 md:py-4 2xl:p-4;
}
:is(.ProductCard.lg, .ProductCard.lg2) .cardBody {
	/* @apply md:absolute md:translate-y-full !bg-primary-tints1/80 bottom-0 left-0; */
	/* @apply !w-full transition-transform duration-500 ease-out; */
	/* transform: translateY(100%); */
}
:is(.ProductCard.lg, .ProductCard.lg2):hover .cardBody {
	/* @apply md:translate-y-0; */
	transform: translateY(0);
}
.ProductCard.sm .cardBody {
	@apply p-2 sm:py-2 sm:p-4 sm:pb-3 md:pb-3;
}
.ProductCard.full .cardBody {
	@apply p-6;
}
.ProductCard.xs .cardBody {
	@apply p-0 px-2 sm:px-4 flex-1;
}
.ProductCard.cart,
.ProductCard.cart .cardBody {
	@apply bg-transparent py-1;
}

.ProductCard.cart .cardBody .top {
	@apply flex gap-2 justify-between;
}
.ProductCard.cart .cardBody .top .attributes {
	@apply flex gap-2 flex-wrap;
}
.ProductCard.cart .cardBody .top .attributes .attribute {
	@apply text-[0.6rem] text-gray-300 px-1 border border-gray-600/50 mb-1;
}

.ProductCard .cardBody .cardTextContents {
	@apply flex flex-col items-center text-center;
}
.ProductCard.xs .cardBody .cardTextContents {
	@apply items-start text-left;
}
.ProductCard.md .cardBody .cardTextContents,
.ProductCard.lg2 .cardBody .cardTextContents {
	/* @apply text-center; */
}
.ProductCard.md .cardBody .cardTextContents > *,
.ProductCard.lg2 .cardBody .cardTextContents > * {
	/* @apply justify-center; */
}
.ProductCard .cardBody .cardTextContents > * {
	@apply sm:mt-0.5;
}
.ProductCard.sm .cardBody .cardTextContents > * {
	@apply mt-[1px];
}
.ProductCard.xs .cardBody .cardTextContents > * {
	@apply mt-0.5;
}

.ProductCard.xs .cardTitle {
	@apply h-auto;
}
.cardTitle {
	@apply !leading-[1.3] text-[12px] text-black sm:text-[14px] md:text-base font-semibold mt-0;
	@apply overflow-hidden mb-1 h-9 md:h-11;
	display: -webkit-box;
	-webkit-line-clamp: 2;
	-webkit-box-orient: vertical;
	/* hide 3rd line */
	text-overflow: ellipsis;
}

@media (max-width: 600px) {
	.cardTitle {
		@apply h-8;
	}
}

.ProductCard.full .cardBody .cardTextContents .cardTitle {
	@apply text-[16px];
}
.ProductCard.xs .cardBody .cardTextContents .cardTitle {
	@apply text-xs sm:text-sm font-semibold w-full;
}
.ProductCard.xl .cardBody .cardTextContents .cardTitle {
	@apply mt-0;
}

.ProductCard .cardBody .buttonsContainer {
	@apply relative;
}
.ProductCard .cardBody .buttonsContainer .buttons {
	/* @apply hidden mt-2 2xl:grid grid-cols-2 gap-2; */
	@apply mt-1.5 flex gap-2 items-center flex-wrap;
}
.ProductCard .cardBody .buttonsContainer .buttons .button {
	/* @apply w-full; */
}
.ProductCard .cardBody .buttonsContainer .message {
	@apply w-full text-center p-2 py-1 border-orange-500 rounded-xl;
	@apply font-semibold text-orange-500;
}

.ProductCard.lg2,
.ProductCard.sm {
	@apply overflow-hidden;
}
.ProductCard.lg2 .cardBody .buttons,
.ProductCard.sm .cardBody .buttons {
	@apply md:transition-opacity duration-500;
	/* @apply w-full grid grid-cols-2 gap-2; */
}
.ProductCard.lg2:hover .cardBody .buttons,
.ProductCard.sm:hover .cardBody .buttons {
	@apply md:opacity-100;
}

.ProductCard.full .cardBody .buttons .addToCartBtn {
	@apply !w-auto px-6;
}
.ProductCard.md .cardBody .buttons,
.ProductCard.xl .cardBody .buttons {
	@apply !hidden 2xl:hidden;
}
.ProductCard .cardBody .buttons .quantityBtn {
	border: 1px solid #e6e6e6;
	@apply flex mr-2 border-gray-300/10;
	/* @apply hidden 2xl:flex; */
}

/** Product Card lg2 **/

/** CardReviewText **/
.ProductCard .cardBody .cardTextContents .cardReviewText,
.CardReviewText {
	@apply flex items-center;
}
.ProductCard .cardBody .cardTextContents .cardReviewText > *,
.CardReviewText > * {
	@apply mr-1;
}
.ProductCard .cardBody .cardTextContents .cardReviewText .icon *,
.CardReviewText .icon * {
	@apply fill-blue-400;
}
.ProductCard .cardBody .cardTextContents .cardReviewText .rating,
.CardReviewText .rating {
	@apply font-bold text-[13px];
}
.ProductCard .cardBody .cardTextContents .cardReviewText .count,
.CardReviewText .count {
	@apply font-normal text-gray-300;
}

/* CardPriceTag */
.ProductCard .cardBody .cardTextContents .cardPriceTag,
.CardPriceTag {
	/* @apply text-lg; */
	@apply flex items-center justify-center gap-x-2 flex-wrap;
}
.CardPriceTag.reverse {
	@apply flex-row-reverse justify-end;
}

.ProductCard .cardBody .cardTextContents .cardPriceTag .prePrice,
.CardPriceTag .prePrice {
	@apply text-[10px] md:text-[12px] text-gray-300 relative;
}
.CardPriceTag.lg .price {
	@apply text-base md:text-[1.5rem] font-[700] text-orange-500;
}
.CardPriceTag.lg .prePrice {
	@apply text-[0.8rem] md:text-[1rem];
}

.ProductCard .cardBody .cardTextContents .cardPriceTag .prePrice span,
.CardPriceTag .prePrice span {
	@apply absolute top-1/2 left-0 w-full h-[1px] bg-gray-300;
}

.ProductCard.xs .cardBody .cardTextContents .cardPriceTag .price {
	@apply text-sm sm:text-base !font-bold;
}
.ProductCard.xs .cardBody .cardTextContents .cardPriceTag .prePrice {
	@apply text-[0.7rem];
}
.ProductCard .cardBody .cardTextContents .cardPriceTag .price,
.CardPriceTag .price {
	@apply text-[0.9rem] md:text-[1rem] font-[700] text-orange-500;
}

/* cart */
.ProductCard.cart .cardBody .buttonsContainer .quantityBtn {
	@apply py-1;
}
.ProductCard.cart .cardBody .buttonsContainer .removeButton {
	@apply py-1;
}
.ProductCard.wishlist .cardBody .buttonsContainer .removeBtn {
	@apply py-1 text-[0.8rem] font-semibold;
	@apply opacity-80 transition-opacity;
}
.ProductCard.wishlist .cardBody .buttonsContainer .removeBtn:hover {
	@apply opacity-100;
}

.higherQuantity {
	@apply py-1;
}
.higherQuantity .message {
	@apply text-[0.6rem] !text-left !p-0;
}
.higherQuantity .button {
	@apply text-[0.62rem] text-black underline block;
}

/* Discount Tag */
.DiscountTag {
	@apply py-[4px] px-2 md:py-[6px] md:px-[10px] rounded-[4px];
	@apply text-[10px] text-orange-500;
	@apply bg-orange-500 shadow-md;
}

.ProductCard .brandButton {
	@apply relative w-14 h-4 sm:h-5 md:w-[4.5rem] md:h-7 mb-1.5 sm:mb-2;
}
.ProductCard.outOfStock .cardButton {
	@apply opacity-50;
}

@media (max-width: 375px) {
	.cardButton {
		@apply text-[0.65rem];
	}
}

@keyframes popIn {
	0% {
		transform: translateX(200px);
	}
	50% {
		transform: translateX(-10px);
	}
	100% {
		transform: translateX(0);
	}
}
@keyframes popOut {
	0% {
		transform: translateX(0);
	}
	100% {
		transform: translateX(200px);
	}
}

/* ** out of stock ui */
.ProductCard .outOfStock {
	@apply absolute top-0 left-0 w-full h-full bg-white/50;
	@apply flex flex-col justify-center items-center pt-4;
}
.ProductCard .outOfStock p {
	@apply w-[95%] mb-2 py-1.5 px-1 text-center bg-[#d0cece]/90;
	@apply text-base font-medium text-white;
	/* box-shadow: 0px 2px 10px 0px rgba(255, 231, 217, 0.7); */
}
.ProductCard .outOfStockText {
	@apply w-[95%] py-1.5 px-1 text-center bg-[#d0cece]/20;
	@apply text-base font-medium text-orange-500 rounded-md;
}
