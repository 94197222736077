.layout__container {
	@apply flex items-start font-roboto;
}
.layout__row--one {
	@apply fixed z-20 shadow-xl transform duration-300;
}
.layout__row--two {
	/* @apply transform duration-300; */
}
main {
	@apply pt-20 bg-blue-50 min-h-screen px-6 pb-6;
}
