.showMoreButton {
	@apply text-base font-semibold flex items-center;
	@apply text-orange-500;
}

.showMoreButton span {
	/* @apply hidden md:block */
}

.showMoreButton .icon {
	@apply pl-2;
}
.showMoreButton .icon * {
	@apply stroke-orange-500;
}
